



































































































































































































































































































































import { api } from '@/api';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import axios from 'axios';
import { apiUrl } from '@/env';

import {
    commitAddNotification,
    commitRemoveNotification,
} from '@/store/main/mutations';

// import { IMnemonic } from '@/interfaces';
import { dispatchGetMnemonics } from '@/store/zabbix/actions';
import { readMnemonics } from '@/store/zabbix/getters';

@Component({
    components: {
    },
})

export default class ToolZabbix extends Vue {

  // computed values
  get allAreasSelected() {
    return this.AreaSelected.length === this.AreaItems.length;
  }

  get someAreasSelected() {
    return this.AreaSelected.length > 0 && !this.allAreasSelected;
  }

  get allFilialsSelected() {
    return this.FilialSelected.length === this.FilialItems.length;
  }

  get someFilialsSelected() {
    return this.FilialSelected.length > 0 && !this.allFilialsSelected;
  }

  get allCitiesSelected() {
    return this.CitySelected.length === this.CityItems.length;
  }

  get someCitiesSelected() {
    return this.CitySelected.length > 0 && !this.allCitiesSelected;
  }

  get allRegionsSelected() {
    return this.RegionSelected.length === this.RegionItems.length;
  }

  get someRegionsSelected() {
    return this.RegionSelected.length > 0 && !this.allRegionsSelected;
  }

  get allDevicesSelected() {
    return this.DeviceSelected.length === this.DeviceItems.length;
  }

  get someDevicesSelected() {
    return this.DeviceSelected.length > 0 && !this.allDevicesSelected;
  }

  get areaIcon() {
    // return 'far fa-check-square';
    if (this.allAreasSelected) { return 'far fa-check-square'; }
    if (this.someAreasSelected) { return 'far fa-minus-square'; }
    return 'far fa-square';
  }

  get filialIcon() {
    // return 'far fa-check-square';
    if (this.allFilialsSelected) { return 'far fa-check-square'; }
    if (this.someFilialsSelected) { return 'far fa-minus-square'; }
    return 'far fa-square';
  }

  get cityIcon() {
    // return 'far fa-check-square';
    if (this.allCitiesSelected) { return 'far fa-check-square'; }
    if (this.someCitiesSelected) { return 'far fa-minus-square'; }
    return 'far fa-square';
  }

  get regionIcon() {
    // return 'far fa-check-square';
    if (this.allRegionsSelected) { return 'far fa-check-square'; }
    if (this.someRegionsSelected) { return 'far fa-minus-square'; }
    return 'far fa-square';
  }

  get devicesIcon() {
    // return 'far fa-check-square';
    if (this.allDevicesSelected) { return 'far fa-check-square'; }
    if (this.someDevicesSelected) { return 'far fa-minus-square'; }
    return 'far fa-square';
  }

  get authheader() {
    return {
      headers: {
        Authorization : `Bearer ${this.$store.getters.token}`,
      },
  };
  }
  // pickers variables
  private dateFromMenu: boolean = false;
  private dateToMenu: boolean = false;
  private dateFrom: string = "";
  private dateTo: string = new Date().toISOString().slice(0, 10);
  private Devices: any = {}; 
  // private MainDict: any = {}; // main structure by region // deprecated
  // private choensDeviceIDs: any = []; // list of chosen IDs
  private AreaItems: any = [];
  private AreaSelected: any = [];

  private FilialItems: any = [];
  private FilialSelected: any = [];

  private CityItems: any = [];
  private CitySelected: any = [];
  private MnemonicSelected: any = [];
  private Mnemonics: any = {};

  private RegionItems: any = [];
  private RegionSelected: any = [];

  private DeviceItems: any = []; // devices are bacically base-station's sectors
  private DeviceSelected: any = [];

  private DeviceTypes: any = [];
  private DeviceTypesSelected: any = [];

  private BaseStationTypes: any = [];
  private BaseStationTypesSelected: any = [];  

  private BaseStations: any = [];
  private BaseStationsSelected: any = [];  

  // private DeviceTypes: Set<string> = new Set<string>();
  // private DeviceTypesSelected: Set<string> = new Set<string>();

  // table
  private pagination: any = {sortBy: 'region', descending: true, rowsPerPage: 10};
  private search: string = '';
  private tableHeaders: any =  [
          { text: 'МРФ', filterable: true, value: 'mrf' },
          { text: 'Филиал', filterable: false, value: 'region' },
          { text: 'Город', filterable: false, value: 'city' },
          { text: 'Тип объекта', value: 'bsType' },
          { text: 'Объект', value: 'bsObject' },
          { text: 'Устройство', value: 'device' },
          { text: 'ip-адрес', value: 'ip' },
          { text: 'Тип оборудования', value: 'kind' },
          { text: 'Средняя загрузка DL Mбит\\с', value: 'loadAverageDl' },
          { text: 'Расчётная загрузка DL Mбит\\с', value: 'loadMaxDl' },
          { text: 'Средняя загрузка UL Mбит\\с', value: 'loadAverageUl' },
          { text: 'Расчётная загрузка UL Mбит\\с', value: 'loadMaxUl' },
          { text: 'Среднее количество CPE', value: 'subscribersAvg' },
          { text: 'Максимальное количество CPE', value: 'subscribersMax' },
          { text: 'Расчётное количество CPE', value: 'subscribersMaxAllowed' },
          { text: 'Полоса Мгц', value: 'bandwidth' },
        ];
  private tableData: any = [];
  private tableLoading: boolean = false;

  public async mounted() {
    // set yesterday into fisrst datepicker
    const yesterday: Date = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.dateFrom = yesterday.toISOString().slice(0, 10)

    // get data for filters
    await dispatchGetMnemonics(this.$store);
    const mn = readMnemonics(this.$store);
    this.fillAreaItems(mn);
  }

  private fillAreaItems(mnemonics) {
      for (const key in mnemonics) {
        this.AreaItems.push(key);
      }    
  }

  private clearFilters() {
    this.FilialSelected = [];
    this.CitySelected = [];
    this.clearDeviceFilters();
  }

    private clearDeviceFilters() {
    this.DeviceTypes = [];
    this.BaseStationTypes = [];
    this.BaseStations = [];
    this.DeviceItems = [];

    this.DeviceTypesSelected = [];
    this.BaseStationTypesSelected = [];
    this.BaseStationsSelected = [];
    this.DeviceSelected = [];
  }

  private areaChanged() {
    this.clearFilters();
    this.FilialItems = [];

    for (const area of this.AreaSelected) {
       for (const filial in readMnemonics(this.$store)[area]) {
          this.FilialItems.push(filial);
       }
    }
  }

  private filialChanged() {
    this.CityItems = [];
    this.CitySelected = [];
    this.Mnemonics = {};

    for (const area of this.AreaSelected) {
      for (const filial of this.FilialSelected) {
        for (const city in readMnemonics(this.$store)[area][filial]) {
          this.CityItems.push(city);
          this.Mnemonics[city] = readMnemonics(this.$store)[area][filial][city];
        }
      }
    }

  }

  private async cityChanged() {
    this.clearDeviceFilters();
    this.MnemonicSelected = [];

    for (const city of this.CitySelected) {
      for (const mnem of this.Mnemonics[city]) {
        this.MnemonicSelected.push(mnem);
      }
    }
    await this.getBaseStations();

    this.fillDeviceTypes();
    this.fillBaseStationTypes();
    this.fillBaseStations();    
  }

  private fillDeviceTypes() {
    this.DeviceTypes = [];
  
    for (const device of this.DeviceItems) {
      if (!this.DeviceTypes.includes(device.kind)) {
        this.DeviceTypes.push(device.kind);
      }
    }
  }


  private fillBaseStationTypes() {
    this.BaseStationTypes = [];
  
    for (const device of this.DeviceItems) {
      const bs = this.getBsTypeFromHostName(device.host_name);

      if (!this.BaseStationTypes.includes(bs)) {
        this.BaseStationTypes.push(bs);
      }
    }
  }

  private fillBaseStations() {
    this.BaseStations = [];
  
    for (const device of this.DeviceItems) {
      const bs = this.getBsNameFromHostName(device.host_name);

      if (!this.BaseStations.includes(bs)) {
        this.BaseStations.push(bs);
      }
    }
  }

  private regionsChanged() {
    this.DeviceItems = [];
    for (const region of this.RegionSelected) {
      for (const device of this.Devices[region]) {
        this.DeviceItems.push(device);
      }
    }
  }

  private baseStationTypesChanged() {
    this.DeviceSelected = [];
    this.BaseStationsSelected = [];
    this.DeviceTypesSelected = [];

    for (const device of this.DeviceItems) {
      const bs = this.getBsTypeFromHostName(device.host_name);
      if (this.BaseStationTypesSelected.includes(bs)) {
        this.DeviceSelected.push(device.host_id);
      }
    }    
  }

  private baseStationsChanged() {
    this.DeviceSelected = [];
    this.BaseStationTypesSelected = [];
    this.DeviceTypesSelected = [];

    for (const device of this.DeviceItems) {
      const bs = this.getBsNameFromHostName(device.host_name);
      if (this.BaseStationsSelected.includes(bs)) {
        this.DeviceSelected.push(device.host_id);
      }
    }    
  }  

  private devicesChanged() {
  }

  private deviceTypesChanged() {
    this.DeviceSelected = [];
    this.BaseStationsSelected = [];
    this.BaseStationTypesSelected = [];

    for (const device of this.DeviceItems) {
      if (this.DeviceTypesSelected.includes(device.kind)) {
        this.DeviceSelected.push(device.host_id);
      }
    }
  }

  private areaToggle() {
        this.$nextTick(() => {
          if (this.allAreasSelected) {
            this.AreaSelected = [];
          } else {
            this.AreaSelected = this.AreaItems;
            // this.RegionSelected = this.fruits.slice()
          }
          this.areaChanged();
        });
  }

  private filialToggle() {
        this.$nextTick(() => {
          if (this.allFilialsSelected) {
            this.FilialSelected = [];
          } else {
            this.FilialSelected = this.FilialItems;
            // this.RegionSelected = this.fruits.slice()
          }
          this.filialChanged();
        });
  }

  private async cityToggle() {
        this.$nextTick(() => {
          if (this.allCitiesSelected) {
            this.CitySelected = [];
          } else {
            this.CitySelected = this.CityItems;
            // this.RegionSelected = this.fruits.slice()
          }
          this.cityChanged();
        });
  }

  private regionToggle() {
        this.$nextTick(() => {
          if (this.allRegionsSelected) {
            this.RegionSelected = [];
          } else {
            this.RegionSelected = this.RegionItems;
          }
          this.regionsChanged();
        });
  }

  private deviceToggle() {
        this.$nextTick(() => {
          if (this.allDevicesSelected) {
            this.DeviceSelected = [];
          } else {
            this.DeviceSelected = this.DeviceItems.map((item) => item.host_id);
          }
          this.devicesChanged();
        });
  }

  private getSCV() {
      const csv = this.convertToCSV(this.tableData, this.tableHeaders);
      if (csv != null) {
        this.forceFileDownload(csv);
      }
      return;
  }

  private  forceFileDownload(data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'bsload.csv'); // or any other extension
      document.body.appendChild(link);
      link.click();
    }

  private convertToCSV(tableData, tableHeaders) {
    let csv = '';

    const headers = tableHeaders.map(header => header.text);
    csv += headers.join(',') + '\n';

    tableData.forEach(row => {
      const rowData = tableHeaders.map(header => {
        const value = row[header.value];
        // Если значение содержит запятую, заключаем его в двойные кавычки
        return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
      });
      csv += rowData.join(',') + '\n';
    });

    return csv;
  }

  private convertTableData(tableData) {
    for (var i = tableData.length - 1; i >= 0; i--) {
      let row = tableData[i];
    
      tableData[i].bsType = this.getBsTypeFromHostName(row.device);
      tableData[i].bsObject = this.getBsNameFromHostName(row.device);
    }

    return tableData
  }

  private getBsTypeFromHostName(hostname) {
    return hostname.split('.')[1].split('-')[0].toLowerCase(); // for ex 'mbs' from  sec1.mbs-001.ulu
  }

  private getBsNameFromHostName(hostname) {
    return hostname.split('.')[1]+'.'+hostname.split('.')[2].toLowerCase(); // for ex 'mbs-001.ulu' from  sec1.mbs-001.ulu
  }

  // getBaseStations gets list of BSes according to chosen mnemonic list
  private async getBaseStations() {
    this.DeviceItems = []
    if (!this.$store.getters.isLoggedIn) { return; }
    if (this.MnemonicSelected.length === 0) {
      commitAddNotification(this.$store, { content: 'локации не выбраны', color: 'warning' });
      return;
    }

    const pl = {
      items : this.MnemonicSelected,// Object.values(),
    };

    const loadingNotification = { content: 'выгружаем список базовых станций для выбранных локаций', showProgress: true };
    commitAddNotification(this.$store, loadingNotification);

    try {
      const response = await api.getBaseStations(this.$store.getters.token, pl)
      commitRemoveNotification(this.$store, loadingNotification);
      if (response) {
        if (response.data.length === 0) {
          commitAddNotification(this.$store, { content: 'нет информации по выбранным базовым станциям', color: 'warning' });
          return;
        }
        this.DeviceItems = response.data.items;
        }
      } catch (error) {
        const text = `Не удалось получить данные: Код ${error.response.status} ${error.response.statusText}`;
        commitAddNotification(this.$store, { content: text, color: 'error' });
      }
  }

  private async getData() {
    if (!this.$store.getters.isLoggedIn) { return; }
    if (this.DeviceSelected.length === 0) {
      commitAddNotification(this.$store, { content: 'Устройства не выбраны', color: 'warning' });
      return;
    }

    const pl = {
      bslist : this.DeviceSelected,
      DateTimeFrom : this.dateFrom,
      DateTimeTo : this.dateTo,
    };

    this.tableData = [];
    this.tableLoading = true;
    const loadingNotification = { content: 'выгружаем данные ...', showProgress: true };
    commitAddNotification(this.$store, loadingNotification);

    try {
      const response = await api.getBSLoadAverage(this.$store.getters.token, pl)
      commitRemoveNotification(this.$store, loadingNotification);
      this.tableLoading = false;
      if (response) {
        if (response.data.length === 0) {
          commitAddNotification(this.$store, { content: 'нет данных', color: 'warning' });
          return;
        }
        this.tableData = this.convertTableData(response.data);
        }
    } catch (error) {
      this.tableLoading = false;
      const text = `Не удалось получить данные: Код ${error.response.status} ${error.response.statusText}`;
      commitAddNotification(this.$store, { content: text, color: 'error' });
    }


    // axios.post(`${apiUrl}/api/v1/zabbix/bsload_average/`, pl , this.authheader)
    // .then((response) => {
    //   this.tableLoading = false;
    //   commitRemoveNotification(this.$store, loadingNotification);

    //   if (response.data.length === 0) {
    //     commitRemoveNotification(this.$store, loadingNotification);
    //     commitAddNotification(this.$store, { content: 'нет данных', color: 'info' });
    //     return;
    //   }

    //   this.tableData = this.convertTableData(response.data);

    //  }).catch((error) => {
    //   if ('response' in error) {
    //     this.tableLoading = false;

    //     const text = `Не удалось получить данные: Код ${error.response.status} ${error.response.statusText}`;
    //     commitAddNotification(this.$store, { content: text, color: 'error' });
    //     return;
    //   }
    //  });
  }

}
