






























import { Component, Watch, Vue } from 'vue-property-decorator';

import ToolZabbix from '@/views/tools/zabbix.vue';


// @Component

@Component({
    components: {
        ToolZabbix,
    },
})
export default class ZabbixTabs extends Vue {
  private tab: any = null;
  private items: string[] = ['загрузка секторов'];
}
